<template>
  <v-skeleton-loader
    v-bind="$attrs"
    :height="height"
    :min-height="height"
    :max-height="height"
    transition="fade-transition"
    type="image"
  >
    <slot />
  </v-skeleton-loader>
</template>
<script>
export default {
  props: {
    height: {
      type: Number
    }
  },
  name: 'ItemLoaderImage',
}
</script>
<style lang="scss">
.v-skeleton-loader__image {
  height: 100%;
}
</style>